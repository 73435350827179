import * as React from 'react';
import type { SVGProps } from 'react';

function SvgCalendarDayIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.33333 5.61865H14.6667M7.16666 8.95199H7.99999V11.452M2.99999 14.7853H13C13.9205 14.7853 14.6667 14.0391 14.6667 13.1187V3.11865C14.6667 2.19818 13.9205 1.45199 13 1.45199H2.99999C2.07952 1.45199 1.33333 2.19818 1.33333 3.11865V13.1187C1.33333 14.0391 2.07952 14.7853 2.99999 14.7853Z'
        stroke='#7A43E8'
        stroke-width='1.75'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
export default SvgCalendarDayIcon;
