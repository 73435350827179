import * as React from 'react';
import type { SVGProps } from 'react';

function SvgPizza(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 14 14' {...props}>
      <path
        fill='currentColor'
        d='M13.971 2.938a1 1 0 0 0-.453-.62 12.59 12.59 0 0 0-13.036 0 1 1 0 0 0-.336 1.376l6 9.83a1 1 0 0 0 1.71 0l6-9.83a1 1 0 0 0 .115-.756M2.974 6.406a1.5 1.5 0 1 1 1.53 2.506zm5.495 4.185A1.5 1.5 0 0 1 10 8.086zm2.059-3.371a2.5 2.5 0 0 0-2.587 4.236L7 13 5.031 9.777a2.5 2.5 0 1 0-2.584-4.235l-.397-.65a9.5 9.5 0 0 1 9.9 0zm1.943-3.183a10.51 10.51 0 0 0-10.942 0L1 3.173a11.6 11.6 0 0 1 12 0z'
      />
    </svg>
  );
}
export default SvgPizza;
