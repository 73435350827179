import * as React from 'react';
import type { SVGProps } from 'react';

function SvgHomeMoveOutIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.8333 14.7853H3C2.07952 14.7853 1.33333 14.0391 1.33333 13.1187V6.28565C1.33333 5.75048 1.59032 5.24786 2.02418 4.93452L6.98194 1.35392C7.58467 0.918609 8.40284 0.935173 8.98746 1.39452L13.8333 5.20199'
        stroke='#7A43E8'
        stroke-width='1.75'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
export default SvgHomeMoveOutIcon;
