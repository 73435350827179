import * as React from 'react';
import type { SVGProps } from 'react';

function SvgFile03Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.66667 1.61865V4.95199C8.66667 5.173 8.75447 5.38496 8.91075 5.54124C9.06703 5.69752 9.27899 5.78532 9.5 5.78532H12.8333M8.66667 1.61865H2.83334C2.39131 1.61865 1.96739 1.79425 1.65483 2.10681C1.34227 2.41937 1.16667 2.84329 1.16667 3.28532V14.952C1.16667 15.394 1.34227 15.8179 1.65483 16.1305C1.96739 16.4431 2.39131 16.6187 2.83334 16.6187H11.1667C11.6087 16.6187 12.0326 16.4431 12.3452 16.1305C12.6577 15.8179 12.8333 15.394 12.8333 14.952V5.78532M8.66667 1.61865L12.8333 5.78532M4.50001 6.61865H5.33334M4.50001 9.95199H9.5M4.50001 13.2853H9.5'
        stroke='#7A43E8'
        stroke-width='1.66667'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
export default SvgFile03Icon;
