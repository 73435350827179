import { useEffect, useState } from 'react';
import clsx from 'clsx';

type BarProps = {
  level: number;
};

export default function Bar({ level }: BarProps) {
  const [currentLevel, setCurrentLevel] = useState(level - 1);

  const levelNames: { [key: number]: string } = {
    1: 'Low Coverage',
    2: 'Standard Coverage',
    3: 'Competitive Coverage',
  };

  const levels = Object.keys(levelNames);

  useEffect(() => {
    setCurrentLevel(level - 1);
  }, [level]);

  return (
    <div className='bg-kota-primary-10 flex items-center space-x-1 rounded px-2 py-1'>
      <div className='text-kota-primary-60 text-xs font-semibold capitalize'>{levelNames[level]}</div>

      <div className='flex h-3 items-end space-x-px'>
        {levels.map((_, index) => {
          const height = (100 / levels.length) * (index + 1);

          return <ScoreBar key={index} height={height} active={currentLevel >= index} />;
        })}
      </div>
    </div>
  );
}

function ScoreBar({ height, active }: { height: number; active?: boolean }) {
  return (
    <div
      style={{ height: `${height}%` }}
      className={clsx('w-1 rounded-sm', {
        'bg-white': !active,
        'bg-kota-primary-50': active,
      })}
    />
  );
}
