import * as React from 'react';
import type { SVGProps } from 'react';

function SvgHomeMoveInIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.8333 14.7853H2.99999C2.07952 14.7853 1.33333 14.0391 1.33333 13.1186V6.28565C1.33333 5.75047 1.59032 5.24786 2.02418 4.93452L6.98194 1.35391C7.58467 0.918605 8.40284 0.935169 8.98746 1.39451L13.8333 5.20198M10.5 8.95198H5.5M5.5 8.95198L8 11.452M5.5 8.95198L7.99999 6.45198'
        stroke='#7A43E8'
        stroke-width='1.66667'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
export default SvgHomeMoveInIcon;
